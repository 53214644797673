#order-search-bar {
  position: relative;
  width: 100%;
  z-index: 701;
}

/* #order-search-bar:before,
#order-search-bar:hover:before {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 24px;

  -webkit-transition: opacity 0.35s ease-in-out;
  -moz-transition: opacity 0.35s ease-in-out;
  -ms-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

#order-search-bar:before {
  left: 10px;
  content: '\f002';
  opacity: 0;
}

#order-search-bar:hover:before {
  opacity: 0.3;
} */

#order-search-bar input {
  padding: 15px 40px !important;
}
